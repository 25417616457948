body {
  margin: 0;
  padding: 0;
  background-color: #c4c6bc;
  font-family: "Lucida Sans", "Lucida Sans Regular", Geneva, Verdana, sans-serif;

  .content {
    display: flex;
  }
}

@import "./menu/menu.scss";
@import "./menu/log.scss";
@import "./faq/faq.scss";
@import "./left/left_container.scss";
@import "./right/right_container.scss";
