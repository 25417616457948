#left_container {
  width: 370px;
  padding: 50px 20px 50px 50px;

  .general_info {
    border-top: 2px dashed black;
    font-size: 14px;

    .info {
      display: flex;
      justify-content: space-between;
    }

    .last_logo img {
      box-shadow: 1px 1px 5px 5px #000000;
      width: 130px;
      margin: 10px;
    }
    .last_logo div {
      display: flex;
    }
  }
}
