.log_panel {
  text-align: center;
  margin: 30px auto;
  font-size: 16px;
  h1 {
    width: 350px;
  }
  div {
    margin-top: 60px;
    label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      font-size: 1.6em;
      input {
        margin-left: 20px;
      }
    }
    .submit_btn {
      margin-top: 20px;
      width: 160px;
      height: 40px;
      background-color: white;
      border-radius: 5px;
      border: 1px solid gray;
      font-size: 1.3em;
      margin-top: 50px;
      &:hover {
        cursor: pointer;
        background-color: #4f4e4e;
        border: 1px solid grey;
        color: white;
      }
    }
    .errors {
      color: red;
      font-weight: bold;
      font-size: 12px;
    }
    .resetPassword {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
