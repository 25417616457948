.faq_container {
  max-width: 1024px;
  margin: auto;
  font-size: 16px;
  .important {
    color: red;
    font-weight: bold;
  }
  h1 {
    font-size: 2.5em;
    text-align: center;
  }
  h2 {
    font-size: 2em;
  }
  p {
    font-size: 1.3em;
  }
  div {
    padding-left: 25px;
    p {
      font-size: 1.1em;
    }
  }
}
