#right_container {
  width: 100%;
  padding: 50px 20px 20px 50px;

  .dropzone {
    margin: 50px auto;
    width: 70%;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    border: 2px dashed black;
    text-align: center;
    cursor: pointer;
    box-shadow: 1px 1px 5px 5px #a4a0a0;

    &:hover {
      background-color: #b1b5b2;
    }
  }

  .logo_library {
    display: flex;
    flex-wrap: wrap;

    .logo {
      position: relative;
      margin: 5px;
      display: flex;

      .logo_wrapper,
      .logo_wrapper_second {
        position: relative;
        width: 80px;
        height: 100px;
        border-width: 40px 25px 40px 25px;
        border-style: solid;
        border-color: #4e4e4e;
        background-color: white;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px;
        img {
          width: 70px;
        }
        .logo_number {
          font-size: 26px;
          font-weight: bolder;
        }
      }
      .inverted {
        background-color: black;
        color: white;
      }

      .logo_wrapper_second {
        img {
          position: absolute;
          top: 2px;
          left: 8px;
          width: 80%;
          height: 25%;
        }
        .no_logo {
          font-size: 8px;
          position: absolute;
          top: 0;
          text-align: center;
        }
      }

      .logo_description {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 100%;
        background: rgba(196, 198, 188, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        text-transform: capitalize;
      }
      .logo_delete {
        position: absolute;
        bottom: 2px;
        left: 95px;
        width: 80px;
        height: 20px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: rgba(255, 0, 0, 0.9);
        color: white;
        border: none;
        border-radius: 5px;
      }
    }
    .no_library_data {
      margin: 40px auto;
      font-size: 26px;
    }
  }
  .btn {
    padding: 6px 12px;
    margin: 10px 10px 10px 0;
    width: 150px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
  }
  .btn-failed {
    color: #fff;
    background-color: #ff0000;
    border-color: #ee7a2d;
  }
  .btn-skip {
    color: #fff;
    background-color: #f0b30d;
    border-color: #ff8800;
  }

  .preview {
    margin: 20px 10px;
    width: 100px;
    height: 100px;
    filter: grayscale(1) invert(0);
  }
  .big_logo_preview {
    margin: 20px 10px;
    width: 144px;
    height: 144px;
    filter: grayscale(1) invert(0);
  }
  .small_logo_preview {
    margin: 20px 10px;
    width: 104px;
    height: 56px;
    filter: grayscale(1) invert(0);
  }
  .final_preview,
  .processing {
    text-align: center;
  }
}
