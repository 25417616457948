#menu {
  height: 70px;
  display: flex;
  justify-content: space-between;
  background: #4f4e4e;
  box-shadow: 0px 5px 5px #000000;

  .logo {
    height: 100%;
    padding-left: 25px;
    display: flex;
    align-items: center;
    img {
      height: 60px;
    }
  }
  p {
    padding-left: 25px;
    color: white;
    font-size: 20px;
    text-transform: capitalize;
  }

  .menu_panel {
    height: 100%;
    display: flex;
    align-items: center;
    ul {
      display: flex;
      padding-right: 25px;

      li {
        padding-right: 25px;
        list-style: none;
        color: white;
        cursor: pointer;

        &:hover {
          transform: scale(1.1);
        }
        a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}
